import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { AuthModel, UserDataModel } from '../models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DepartmentModel } from '../../departments/models/department.model';

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
  private readonly REFRESH_TOKEN = 'refreshToken';

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;
  userDetailsObj: UserDataModel;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private http: HttpClient
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
    this.getUserDetailsFromLocalStorage();
  }

  // public methods
  login(email: string, password: string): Observable<UserType> | any {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((auth: AuthModel) => {
        const result = this.setAuthFromLocalStorage(auth);
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  
  // public methods
  logIn(email: string, password: string,departments:DepartmentModel[]): Observable<UserType | any> {
    this.isLoadingSubject.next(true);

    const body = new URLSearchParams();
    body.set('username', email);
    body.set('password', password);

    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });

    return this.http
      .post(`${environment.API_BASE_URL}/api/Token`, body.toString(), {
        headers,
      })
      .pipe(
        map((res: any) => {
          if(this.hasActiveDepartment(
            res,
            departments
          )){
            this.currentUserSubject.next(res);
            this.setAuthFromLocalStorage(res);
  
            Object.keys(res).forEach((key) => {
              console.log(res,key)
              if(key=='token'){
                const jwtToken = JSON.parse(atob(res[key].split('.')[1]));
                const expires = new Date(jwtToken.exp * 1000);
                localStorage.setItem('exp',(jwtToken.exp * 1000).toString())
                // const expiry = (JSON.parse(atob(res[key].split('.')[1]))).exp;
                // return expiry * 1000 > Date.now();
              }
              localStorage.setItem(key, JSON.stringify(res[key]));
              // localStorage.setItem(key, JSON.stringify(key));
            });
          }
          this.isLoadingSubject.next(true);
          return res;
        }),
        catchError((err) => {
          console.error('err', err);
          return of(err);
        }),
        finalize(() => this.isLoadingSubject.next(false))
      );
  }

  hasActiveDepartment(user: any, departments: DepartmentModel[]): boolean {
    return user.departments.some((userDeptId: string) => {
      return departments.some(
        (dept) => dept.id === userDeptId && dept.isActive
      );
    });
  }

  private saveRefreshToken(refreshToken: string) {
    localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
  }

  getCurrentLoggedInUser(): Observable<UserModel | boolean> {
    return this.http.get<UserType>('/api/Token').pipe(
      map((res: any) => {
        this.setAuthFromLocalStorage(res);
        return res;
      })
    );
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    localStorage.removeItem(this.REFRESH_TOKEN);
    this.router.navigate(['/auth/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.token).pipe(
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: UserModel): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }),
      switchMap(() => this.login(user.email, user.password)),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // Stores the Array of values on the local storage
  array_set(key: any, data: any): void {
    for (let i = 0; i < key.length; i++) {
      localStorage.setItem(key[i], data[i]);
    }
  }

  // private methods
  private setAuthFromLocalStorage(auth: AuthModel): boolean {
    // store auth token/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel | undefined {
    try {
      const lsValue = localStorage.getItem(this.authLocalStorageToken);
      if (!lsValue) {
        return undefined;
      }

      const authData = JSON.parse(lsValue);
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  private getUserDetailsFromLocalStorage() {
    try {
      const firstName = localStorage.getItem('firstName');
      const lastName = localStorage.getItem('lastName');
      const email = localStorage.getItem('email');
    
      // Parse the retrieved values if they exist
      const parsedFirstName = firstName ? JSON.parse(firstName) : null;
      const parsedLastName = lastName ? JSON.parse(lastName) : null;
      const parsedEmail = email ? JSON.parse(email) : null;
    
      // Assign the parsed values to the properties of the UserModel object
      this.userDetailsObj = {
        firstName: parsedFirstName,
        lastName: parsedLastName,
        email: parsedEmail
      };
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  getLocalStorageValues(key: string) {
    return JSON.parse(localStorage.getItem(key) ?? '');
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
